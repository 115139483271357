import React, { forwardRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./CustomPhoneInput.css";

const CustomPhoneInput = forwardRef(({ value, onChange }, ref) => {
  return (
    <PhoneInput
      country={"in"}
      value={value}
      onChange={onChange}
      autoFocus={false}
      countryCodeEditable={false}
      inputRef={ref} // Pass the ref to the PhoneInput component
      placeholder=' ' // Set the placeholder text here
    />
  );
});

export default CustomPhoneInput;
