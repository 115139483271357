import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


export const Navigation = () => {

  const [, setShowButton] = useState(false);

  const scrollToTop = () => {
    const scrollStep = -window.scrollY / (1000 / 15);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  const handleButtonClick = () => setTimeout(scrollToTop, 1000);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {" "}
            <span className='sr-only'>Toggle navigation</span>{" "}
            <span className='icon-bar'></span>{" "}
            <span className='icon-bar'></span>{" "}
            <span className='icon-bar'></span>{" "}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            Research Wave
          </a>{" "}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <Link to='/' className='page-scroll' onClick={handleButtonClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to='/contact' className='page-scroll'>
                Contact
              </Link>
            </li>
            <li>
              <Link to='/features' className='page-scroll'>
                Features
              </Link>
            </li>
            <li>
              <Link to='/about' className='page-scroll'>
                About
              </Link>
            </li>
            <li>
              <Link to='/services' className='page-scroll'>
                Services
              </Link>
            </li>
            <li>
              <Link to='/gallery' className='page-scroll'>
                Gallery
              </Link>
            </li>
            <li>
              <Link to='/testimonials' className='page-scroll'>
                Testimonials
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
