import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; 
import Home from "./components/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import { Navigation } from "./components/Navigation";
import { Features } from "./components/Features";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { Gallery } from "./components/Gallery";
import { Testimonials } from "./components/Testimonials";
import JsonData from "./Data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Privacy } from "./components/Privacy";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <BrowserRouter>
      <>
        <Navigation />
        <Routes>
          {" "}
          <Route
            path='/contact'
            element={<Contact data={landingPageData.Contact} />}
          />
          <Route
            path='/features'
            element={<Features data={landingPageData.Features} />}
          />
          <Route
            path='/about'
            element={<About data={landingPageData.About} />}
          />
          <Route
            path='/services'
            element={<Services data={landingPageData.Services} />}
          />
          <Route
            path='/gallery'
            element={<Gallery data={landingPageData.Gallery} />}
          />
          <Route
            path='/testimonials'
            element={<Testimonials data={landingPageData.Testimonials} />}
          />
          <Route
            path='/privacy-policy'
            element={<Privacy />}
          />
          <Route path='/' element={<Home />} />
        </Routes>
        <Footer data={landingPageData.Contact} />
      </>
    </BrowserRouter>
  );
};

export default App;
