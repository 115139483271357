const ContactFormValidator = ({
  studentName,
  phone,
  email,
  state,
  studentClass,
  institute,
}) => {
  const emptyFields = [];
  
  if (!studentName.trim()) {
    emptyFields.push("Name");
  }
  if (!phone || phone === "") {
    emptyFields.push("Phone Number");
  }
  if (!email.trim()) {
    emptyFields.push("Email");
  }
  if (!state || state === "--Select State--" || state === "") {
    emptyFields.push("State");
  }
  if (!studentClass.trim()) {
    emptyFields.push("Class");
  }
  if (!institute.trim()) {
    emptyFields.push("Institute");
  }
  return emptyFields;
};

export default ContactFormValidator;
