import React from "react";

export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Unlock the full potential of your EdTech platform with our
            comprehensive digital marketing services tailored specifically for
            the education industry. From strategic content creation and search
            engine optimization to targeted advertising campaigns, we specialize
            in elevating your online presence. Our team of experts understands
            the unique challenges and opportunities within the EdTech space,
            ensuring your platform reaches its intended audience effectively.
            With data-driven insights and innovative strategies, we drive user
            engagement, boost conversions, and ultimately, enhance the
            educational experience for students and educators alike. Partner
            with us and harness the power of digital marketing to take your
            EdTech venture to new heights.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {" "}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
