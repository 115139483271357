// import "dotenv/config";

export const firebaseConfig = {
  apiKey: "AIzaSyDWmRddCk4DlgZJrWnmGqiGyViyHH0vYpo",
  authDomain: "researchwave-ea995.firebaseapp.com",
  projectId: "researchwave-ea995",
  storageBucket: "researchwave-ea995.appspot.com",
  messagingSenderId: "495938040897",
  appId: "1:495938040897:web:79e1c24392b496180682c0",
  measurementId: "G-NKZMB0DWLS",
  // apiKey: process.env.FIREBASE_API_KEY,
  // authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.FIREBASE_PROJECT_ID,
  // storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.FIREBASE_APP_ID,
};
