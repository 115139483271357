import { addDoc, collection } from "firebase/firestore/lite";
import React, { useRef, useState } from "react";
import { db } from "../firebase";
import ContactFormValidator from "./ContactFormValidator";
import CustomPhoneInput from "./CustomPhoneInput";
import StateSelect from "./StateSelect";

const Contact = (props) => {
  const [selectedState, setSelectedState] = useState("");
  const phoneInputRef = useRef(null);
  const [errorText, setErrorText] = useState("");

  const [formData, setFormData] = useState({
    studentName: "",
    phone: "",
    email: "",
    state: selectedState,
    studentClass: "",
    institute: "",
  });

  const [showThankYou, setShowThankYou] = useState(false);

  const handleStateChange = (e) => {
    const newStateValue = e.target.value;
    setSelectedState(newStateValue);

    setFormData((prevState) => {
      const updatedState = { ...prevState, state: newStateValue };
      return updatedState;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { studentName, phone, email, state, studentClass, institute } =
      formData;

    const isValid = ContactFormValidator({
      studentName,
      phone,
      email,
      state,
      studentClass,
      institute,
    });

    if (isValid.length > 0) {
      setErrorText(
        `Please fill out the following fields: ${isValid.join(", ")}`
      );
    } else {
      setErrorText("");
      try {
        const currentTime = new Date();
        const formattedTime = currentTime.toISOString();

        await addDoc(collection(db, "researchwavedb"), {
          name: formData,
          timestamp: formattedTime,
        });
      } catch (e) {
        setTimeout(() => {
          window.location.href = "www.researchwave.co.in/";
        }, 2000);
      }
      setShowThankYou(true);
      setSelectedState("");
      setFormData({
        studentName: "",
        phone: "",
        email: "",
        state: selectedState,
        studentClass: "",
        institute: "",
      });
      // Reset phone input value using the ref
      phoneInputRef.current?.resetNumber();
      setTimeout(() => {
        setShowThankYou(false);
      }, 3000);
    }
  };

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              {showThankYou ? (
                <div>
                  <h3>Thank you for submitting the form!</h3>
                  <p>We will get back to you shortly.</p>
                </div>
              ) : (
                <form name='sentMessage' validate onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          id='studentName'
                          name='studentName'
                          className='form-control'
                          placeholder='Name'
                          required
                          autoComplete='off'
                          // autoFocus
                          value={formData.studentName}
                          onChange={handleChange}
                        />
                        <p className='help-block text-danger'></p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        {errorText && (
                          <div className='alert alert-danger'>{errorText}</div>
                        )}
                        <CustomPhoneInput
                          ref={phoneInputRef}
                          value={formData.phone}
                          onChange={(value) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              phone: value,
                            }))
                          }
                        />
                        <div id='recaptcha-container'></div>{" "}
                        <p className='help-block text-danger'></p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='email'
                          id='email'
                          name='email'
                          className='form-control'
                          placeholder='Email'
                          required
                          autoComplete='off'
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <p className='help-block text-danger'></p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <StateSelect
                          selectedState={selectedState}
                          onChange={handleStateChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <select
                          id='class'
                          name='studentClass'
                          value={formData.studentClass}
                          onChange={handleChange}
                          className='form-control'
                          required
                        >
                          <option value='' selected>
                            --Select Class--
                          </option>
                          <option value='11th'>11th</option>
                          <option value='12th'>12th</option>
                          <option value='Dropper'>Dropper</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <select
                          id='institute'
                          name='institute'
                          value={formData.institute}
                          onChange={handleChange}
                          className='form-control'
                          required
                        >
                          <option value='' selected>
                            --Select Institute--
                          </option>
                          <option value='Aakash Institute'>
                            Aakash Institute
                          </option>
                          <option value='Allen Institute'>
                            Allen Institute
                          </option>
                          <option value='Resonance'>Resonance</option>
                          <option value='PW'>PW</option>
                          <option value='Unacademy'>Unacademy</option>
                          <option value='FIITJEE'>FIITJEE</option>
                          <option value='Vidyamandir'>Vidyamandir</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div id='success'></div>
                  <button type='submit' className='btn btn-custom btn-lg'>
                    Send Message
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
