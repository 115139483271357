import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./firebase-config"
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
const auth = getAuth(app);


// auth.settings.appVerificationDisabledForTesting = true;


export {app, db, auth, analytics}
