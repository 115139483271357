import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [, setShowButton] = useState(false);

  const scrollToTop = () => {
    const scrollStep = -window.scrollY / (1000 / 15);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  const handleButtonClick = () => setTimeout(scrollToTop, 1000);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <footer>
      <div id='social-links'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <a href='#page-top'>
            <Link to='/privacy-policy' onClick={handleButtonClick}>
              <p>Privacy Policy</p>
            </Link>
          </a>
          <p>&copy; 2023 Research Wave. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
