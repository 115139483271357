import { Image } from "./Image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>
            Explore our dynamic gallery showcasing the transformative impact of
            our digital marketing strategies in the realm of education
            technology. Witness firsthand the success stories, innovative
            campaigns, and engaging content that have empowered educational
            institutions to thrive in the digital landscape. From targeted
            social media campaigns to data-driven SEO strategies, our gallery is
            a testament to our expertise in amplifying online presence for
            edtech platforms. Gain insights into our visually compelling
            designs, interactive content, and user-friendly interfaces that have
            driven impressive results for our edtech clients. Discover how our
            tailored digital marketing solutions are revolutionizing the way
            educational technology reaches and engages its audience.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className='col-sm-6 col-md-4 col-lg-4'
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
